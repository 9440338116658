import React, { useEffect, useState } from 'react';
import angry from "../Imagenes/pleizt-angry.svg";
import confused from "../Imagenes/pleizt-confused.svg";
import happy from "../Imagenes/pleizt-happy.svg";
import love from "../Imagenes/pleizt-in-love.svg";
import sad from "../Imagenes/pleizt-sad.svg";

import "./PreguntasSatisfaccion.css";

export const PreguntaSatisfaccion = props => {

    const { title, description, id, isLast, enviarData, actualizarPreguntaActual, actualizarPreguntaAnterior } = props;

    const [ caritas, setCaritas ] = useState(); // guarda el valor de la carita al hacer click
    // console.log(caritas);
    const [ error, setError ] = useState(false);

    const [buttonActiveAngry, setButtonActiveAngry] = useState(false);
    const [buttonActiveSad, setButtonActiveSad] = useState(false);
    const [buttonActiveConfused, setButtonActiveConfused] = useState(false);
    const [buttonActiveHappy, setButtonActiveHappy] = useState(false);
    const [buttonActiveLove, setButtonActiveLove] = useState(false);

    const btnActiveAngry = (e) => {
        setCaritas(e.target.value);
        console.log(e.target.value);
        setButtonActiveAngry(!buttonActiveAngry);
        setButtonActiveSad(false);
        setButtonActiveConfused(false);
        setButtonActiveHappy(false);
        setButtonActiveLove(false);
        // actualizarPreguntaActual();
    }

    const btnActiveSad = (e) => {
        setCaritas(e.target.value);
        setButtonActiveSad(!buttonActiveSad);
        setButtonActiveAngry(false);
        setButtonActiveConfused(false);
        setButtonActiveHappy(false);
        setButtonActiveLove(false);
        // actualizarPreguntaActual();
    }

    const btnActiveConfused = (e) => {
        setCaritas(e.target.value);
        setButtonActiveConfused(!buttonActiveConfused);
        setButtonActiveAngry(false);
        setButtonActiveSad(false);
        setButtonActiveHappy(false);
        setButtonActiveLove(false);
        // actualizarPreguntaActual();
    }

    const btnActiveHappy = (e) => {
        setCaritas(e.target.value);
        setButtonActiveHappy(!buttonActiveHappy);
        setButtonActiveAngry(false);
        setButtonActiveSad(false);
        setButtonActiveConfused(false);
        setButtonActiveLove(false);
        // actualizarPreguntaActual();
    }

    const btnActiveLove = (e) => {
        setCaritas(e.target.value);
        setButtonActiveLove(!buttonActiveLove);
        setButtonActiveAngry(false);
        setButtonActiveSad(false);
        setButtonActiveConfused(false);
        setButtonActiveHappy(false);
        // actualizarPreguntaActual(); // muestra la siguiente pregunta
        // Se quita esta funcion para que pase a la siguiente pregunta, cuando le da click en la carita.
    }


    const saveDataBoton = () => {
        console.log(caritas);
        if(caritas === undefined ){
            // alert("Por favor selecciona una opción");
        } else {    
            const respuesta = {
                "id": id, "answers": caritas
            }
            console.log(respuesta);
            localStorage.setItem(id, JSON.stringify(respuesta));
            
            // es para verificar si es la ultima pregunta del array y si es la ultima, ejecuta la funcion de enviarData que esta en AppEncuentas.
            if(isLast) {
                enviarData();
            } else {
                actualizarPreguntaActual(); // boton siguiente
            }
        }
    }

    // para enviar la respuesta al localStorage
    useEffect(() => {

            try {
                const localStorageAnswers = localStorage.setItem("respuesta");
    
                let parsedRespuesta;
    
                if(!localStorageAnswers) {
                    localStorageAnswers.setItem("respuesta", JSON.stringify(caritas));
    
                    parsedRespuesta = caritas;
                } else {
                    parsedRespuesta = JSON.parse(localStorageAnswers.getItem("respuesta"));
                }
                setCaritas(parsedRespuesta)
            } catch (error) {
                setError(error);
            }
    }, [])

    
    return (


        <>

            <button style={{ display: "flex", alignSelf: "flex-start", background: "aqua", border: "aqua", borderRadius: "5px", padding: "5px", textAlign: "center", cursor: "pointer", margin: "15px"
            }}  
                onClick={() => actualizarPreguntaAnterior() }
            >↶</button>

            <header className='header-preguntas' >
                <h1>{title}</h1>
                <h4>{description}</h4>
            </header> 

            <section className='container-init'>

                {
                    [...new Array(1)].map((carita, i) => {
                        // const puntuacionCarita = i + 1;
                        // console.log(puntuacionCarita)

                        return(
                            <>
                                <button className={`container-img ${ buttonActiveAngry ? 'isActive' : '' }`} 
                                value="1" 
                                onClick={ btnActiveAngry }
                                style={{ backgroundImage: `url(${angry})`, backgroundPosition: "center", backgroundSize: 'contain', backgroundRepeat: 'no-repeat'}}
                                >
                                </button>
                                
                                <button  className={`container-img ${ buttonActiveSad ? 'isActive' : '' }`} 
                                value="2" onClick={ btnActiveSad } 
                                style={{ backgroundImage: `url(${sad})`, backgroundPosition: "center", backgroundSize: 'contain', backgroundRepeat: 'no-repeat'}}
                                >
                                </button>
                                
                                <button  className={`container-img ${ buttonActiveConfused ? 'isActive' : '' }`} 
                                value="3" onClick={ btnActiveConfused } 
                                style={{ backgroundImage: `url(${confused})`, backgroundPosition: "center", backgroundSize: 'contain', backgroundRepeat: 'no-repeat'}}
                                >
                                </button>
                                
                                <button  className={`container-img ${ buttonActiveHappy ? 'isActive' : '' }`} 
                                value="4" onClick={ btnActiveHappy } 
                                style={{ backgroundImage: `url(${happy})`, backgroundPosition: "center", backgroundSize: 'contain', backgroundRepeat: 'no-repeat'}}
                                >
                                </button>
                                
                                <button className={`container-img ${ buttonActiveLove ? 'isActive' : '' }`}
                                value="5" onClick={ btnActiveLove } 
                                style={{ backgroundImage: `url(${love})`, backgroundPosition: "center", backgroundSize: 'contain', backgroundRepeat: 'no-repeat'}}
                                >
                                </button>  

                            </>
                        );
                    })
                }
            </section>

            <button 
                className="boton" 
                onClick={ (e) => saveDataBoton(e.target.value) } 
                style={{ marginTop: "140px", border: "1px solid #00F2D7", background: "#00F2D7", padding: "9px",
                    cursor: "pointer",
                    width: "272px",
                    borderRadius: "12px" }} 
                
            >Siguiente</button>
        
        </>
    )
}

import "./App.css";
import React, { useEffect, useState } from "react";
import { Login } from "./components/Login/Login";
import { PreguntaRango } from "./components/PreguntaRango/PreguntaRango";
import { PreguntaRespuesta } from "./components/PreguntaRespuesta/PreguntaRespuesta";
import { PreguntaSiNo } from "./components/PreguntaSiNo/PreguntaSiNo";
import { PreguntaSatisfaccion } from "./components/PreguntaSatisfaccion/PreguntaSatisfaccion";
import { PreguntasEstrellas } from "./components/PreguntaEstrellas/PreguntasEstrellas";

import { GraciasFin } from "./components/GraciasFin/GraciasFin";
import { NavbarPreguntas } from "./components/NavbarPreguntas/NavbarPreguntas";

import { useLocation } from "react-router-dom";

function AppEncuestas() {
  const [fecthApi, setFecthApi] = useState([]);
  // console.log(fecthApi); aqui esta toda las informacion completa contiene todo esto:
  const { title, description, logo, uuid } = fecthApi;

  // Estado del array preguntas
  const [openQuestion, setOpenQuestion] = useState([]);

  const [mensajeFinal, setMensajeFinal] = useState();

  const [preguntaActual, setPreguntaActual] = useState("");

  const [preguntaAnterior, setPreguntaAnterior] = useState("");

  const [urlBase, setUrlBase] = useState("https://dev-encuesta.api.pleizt.co");
  // const [searchParams, setSearchParams] = useSearchParams();

  // useLocation().search accede a los parámetros de la url en la que estamos
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const initialUrl = urlBase + `/surveys/${params.get("uuid")}`; // ${uuid} se debe pasar para que cambien las preguntas...
  // console.log(initialUrl);
  // console.log(this.props)

  useEffect(() => {
    (async function () {
      const res = await fetch(initialUrl).then((res) => res.json());
      setFecthApi(res.data);
      if (res.data.questions === undefined) {
        setMensajeFinal("Encuesta no existente");
      } else {
        setOpenQuestion(res.data.questions);
        setPreguntaActual(-1); // Con este estado solo se muestra el login. el -1 no exite
        //console.log(res.data.questions); // aqui si muestra la info
        setPreguntaAnterior();
      }
    })();
  }, [initialUrl]);

  // se esta formando el JSON de las respuestas que se van a enviar al api. (questions)
  const questionsJSON = () => {
    let array = [];
    openQuestion.map((ques, i) => {
      let data = localStorage.getItem(ques.id);
      if (data !== null) {
        // array.push(localStorage.getItem(ques.id));
        array.push(JSON.parse(data)); // aqui se convierte la informacion en JSON. para luego enviarla  en la funcion enviarData
      }
    });
    return array;
  };

  // para enviar esta funcion a otros componentes se debe hacer por medio de props en los otros componentes "PreguntaRango"
  const enviarData = async () => {
    console.log("enviar datos");

    try {
      let arrayRespuestas = questionsJSON();
      let res = await fetch(urlBase + `/surveys/${params.get("uuid")}`, {
        method: "POST",
        body: JSON.stringify({
          // aqui se arma el json para enviarlo al api, de acuerdo a como esta en postman (POST)
          uuid: params.get("uuid"),
          name: localStorage.getItem("Nombre"),
          email: localStorage.getItem("Correo"),
          phone: localStorage.getItem("Telefono"),
          questions: arrayRespuestas,
        }),
      });

      let resJSON = await res.json();
      console.log(resJSON);
      // console.log(resJSON.message);
      if (res.status === 200) {
        let msjFin = resJSON.message;
        // alert(msjFin);
        setMensajeFinal(msjFin);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // actualiza el estado de la pregunta
  const actualizarPreguntaActual = () => {
    let position = preguntaActual + 1; // preguntaActual += 1; // aumenta 1 y actualiza de una vez dentro de la misma variable.

    // Cada vez que se actualiza un estado automaticamente va a actualizar o redibujar la pagina y por eso muestra la siguiente pregunta.
    setPreguntaActual(position); // le suma 1 a la posicion (-1) y actualiza el contenido de la pagina
    console.log("pagina siguiente" + position);
  };

  // actualiza el estado de la pregunta
  const actualizarPreguntaAnterior = () => {
    // let position = preguntaAnterior - 1;
    let position = preguntaActual - 1;
    setPreguntaActual(position);
    console.log("posicion anterior?" + position);
    let paginaAnterior = preguntaActual;
    console.log("preguntaActual" + paginaAnterior);
  };

  const display = () => {
    // undefined es que no existe la posicion en el array(openQuestion).
    if (openQuestion[preguntaActual] !== undefined) {
      // si existe la posicion la guarda en question
      let question = openQuestion[preguntaActual];

      const { id, title, description, type } = question;
      let ultimoItem = openQuestion[openQuestion.length - 1].id;
      let isLast = ultimoItem === id;

      return (
        <>
          {(() => {
            if (type === "range_of_numbers") {
              return (
                <PreguntaRango
                  key={id}
                  title={title}
                  description={description}
                  id={id}
                  isLast={isLast}
                  enviarData={enviarData}
                  actualizarPreguntaActual={actualizarPreguntaActual}
                  actualizarPreguntaAnterior={actualizarPreguntaAnterior}
                />
              );
            } else if (type === "star_rank") {
              return (
                <PreguntasEstrellas
                  key={id}
                  title={title}
                  description={description}
                  id={id}
                  isLast={isLast}
                  enviarData={enviarData}
                  actualizarPreguntaActual={actualizarPreguntaActual}
                  actualizarPreguntaAnterior={actualizarPreguntaAnterior}
                />
              );
            } else if (type === "text") {
              return (
                <PreguntaRespuesta
                  key={id}
                  title={title}
                  description={description}
                  id={id}
                  isLast={isLast}
                  enviarData={enviarData}
                  actualizarPreguntaActual={actualizarPreguntaActual}
                  actualizarPreguntaAnterior={actualizarPreguntaAnterior}
                />
              );
            } else if (type === "selection") {
              return (
                <PreguntaSiNo
                  key={id}
                  title={title}
                  description={description}
                  id={id}
                  isLast={isLast}
                  enviarData={enviarData}
                  actualizarPreguntaActual={actualizarPreguntaActual}
                  actualizarPreguntaAnterior={actualizarPreguntaAnterior}
                />
              );
            } else if (type === "smiley") {
              return (
                <PreguntaSatisfaccion
                  key={id}
                  title={title}
                  description={description}
                  id={id}
                  isLast={isLast}
                  enviarData={enviarData}
                  actualizarPreguntaActual={actualizarPreguntaActual}
                  actualizarPreguntaAnterior={actualizarPreguntaAnterior}
                />
              );
            }
          })()}
        </>
      );
    }
  };

  return (
    <div className="App">
      <>
        {(() => {
          if (mensajeFinal === undefined) {
            console.log("preguntaActual:  " + preguntaActual);
            if (preguntaActual === -1) {
              return (
                <Login
                  title={title}
                  description={description}
                  logo={logo}
                  actualizarPreguntaActual={actualizarPreguntaActual}
                />
              );
            } else {
              return (
                <>
                  {/* <h1>→←↩↪↶↷◀◁▶▷◄►◅▻◂▸◃▹---</h1> */}
                  <NavbarPreguntas logo={logo} />
                  {display()}
                </>
              );
            }
          } else {
            return <GraciasFin Mensaje={mensajeFinal} logo={logo} />;
          }
        })()}
      </>
    </div>
  );
}

export default AppEncuestas;

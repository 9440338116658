import React, { useEffect, useState } from "react";
import "./NavbarLogos.css";
import logoPlaizt from "../Imagenes/Logo.svg";
import decoracion from "../Imagenes/imgdecoration.svg";
export const NavbarLogos = (props) => {
  const { logo } = props;
  // console.log("logo: " + logo);

  return (
    <nav className="espacio-elementos">
      <img
        src={logo}
        style={{
          position: "absolute",
          zIndex: "1",
          left: "50px",
          top: "20px",
          maxHeight: "120px",
        }}
        alt="logoPlaizt"
        className="logoPlaiztCarga"
      ></img>
      <img
        src={decoracion}
        alt="decoracion"
        style={{
          position: "absolute",
          width: "137px",
          zIndex: "0",
          maxHeight: "150px",
        }}
        className="decoracion"
      ></img>
    </nav>
  );
};

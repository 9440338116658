import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import AppEncuestas from './AppEncuestas';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <BrowserRouter>
      <AppEncuestas />
    </BrowserRouter>
  /* </React.StrictMode> */
);

reportWebVitals();

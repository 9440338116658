import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import "./PreguntaRango.css";
// npm i --save react-select >>>> es un paquete de react para hacer el select

// aqui se usa props para recibir la funcion de enviarData y poderla usar en saveDataBoton del onClick del boton.
export const PreguntaRango = props => {

    // se desestructura las props
    const { title, description, id, isLast, enviarData, actualizarPreguntaActual, actualizarPreguntaAnterior } = props;
    
    // Dinamico
    const DBQuestions = [ ];
    for (let i = 1; i < 11; i++) {
        DBQuestions.push({id: i, q: i})
    }
    
    const [select, setSelect] = useState();

    const [error, setError ] = useState(false);

    const onChangeSelect = (e) => {
        setSelect(e.value);
    }

    const saveDataBoton = () => {

        if (select === undefined ) {
            alert("Por favor selecciona una opción");
        } else {
            const respuesta = {
                // id es la pregunta rango y el select es la respuesta que seleciona el usuario en la pagina.
                "id": id, "answers": select
            }
            // aqui se guarda la infor en el localStorage
            localStorage.setItem(id, JSON.stringify(respuesta));
    
            // valida si es la ultima pagina o pregunta del array, y si si llama a enviarData que esta el AppEncuentas.
            if(isLast ) {
                enviarData();
            } else {
                actualizarPreguntaActual();
            }
        }
    }

    useEffect(() => {
      try {
            const localStorageAnswers = localStorage.setItem("respuestaSelector");

            let parsedRespuesta;

            if(!localStorageAnswers){
                localStorageAnswers.setItem("respuestaSelector", JSON.stringify(DBQuestions));
                parsedRespuesta = DBQuestions;

            } else {
                parsedRespuesta = JSON.parse(localStorageAnswers.getItem("respuestaSelector"));
            }
            setSelect(parsedRespuesta);

        } catch(error) {
            setError(error);
        }      
    }, [])
    
    
    return (

        <>

            <button style={{ display: "flex", alignSelf: "flex-start", background: "aqua", border: "aqua", borderRadius: "5px", padding: "5px", textAlign: "center", cursor: "pointer", margin: "15px"
            }}  
                onClick={() => actualizarPreguntaAnterior() }
            >↶</button>

            <header className='header-preguntas' >
                <h1>{title}</h1>
                <h4>{description}</h4>
            </header> 

            {/* listas de seleccion son con bropdown (desplegable) */}
            <div className='question-container' >

                <Select id="select" className='options'
                    defaultValue={ { label: "Selecciona la pregunta", value: select } }
                    options={ DBQuestions.map(ques => ({ label: ques.q, value: ques.id }) ) }
                    onChange={ onChangeSelect }
                    // value={DBQuestions.map(ques => ({ label: ques.q, value: ques.id}) )} 
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 5,
                      width: 172,
                      colors: {
                        ...theme.colors,
                        primary25: '#F3F2F4', // color que indica donde esta ubicado el cursor.
                        primary: '#00F2D7', // color del borde de la caja y seleccion.
                        primary50: '#00F2D7', // color de click.
                      },
                    })}
                />

                <button 
                className="boton" 
                onClick={ () => saveDataBoton() } 
                style={{ marginTop: "140px", border: "1px solid #00F2D7", background: "#00F2D7", padding: "9px",
                    cursor: "pointer",
                    width: "272px",
                    borderRadius: "12px" 
                }} 
                >Siguiente</button>
            </div>
        </>
    )
}

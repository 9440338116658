import React, { useEffect, useState } from 'react';
import "./PreguntaSiNo.css";


export const PreguntaSiNo = props => {

    const { title, description, id, isLast, enviarData, actualizarPreguntaActual, actualizarPreguntaAnterior } = props;

    const [ buttom, setButtom] = useState();
    const [error, setError ] = useState(false);

    const [buttonActive, setButtonActive] = useState( false ); 

    const [buttonActiveSi, setButtonActiveSi] = useState( false ); 
    // cuando el mouse pasa por encima (onMouseEnter)
    // cuando mueva el mouse hacia afuera es onMouseLeave


    const clickBotonSi = (e) => {
        setButtom(e.target.value);
        setButtonActiveSi(true)
        setButtonActive(false);    
    }
        
    const clickBotonNo = (e) =>  {
        setButtom(e.target.value);
        setButtonActive(true)
        setButtonActiveSi(false);
    }

    const saveDataButton = () => {

        if(buttom === undefined){
            alert("Por favor selecciona una opción.")
        } else {
            
            const respuesta = {
                // id de la pregunta
                "id": id, "answers": buttom
            }
            localStorage.setItem(id, JSON.stringify(respuesta));
    
            if(isLast) {
                enviarData();
            } else {
                actualizarPreguntaActual();
            }
        }        
    }

    const mouseDown = () => {
        document.getElementById("ButtonYes").style.color="#F5F5F5";
    }

    const mouseDownNo = () => {
        document.getElementById("ButtonNo").style.color="#F5F5F5";
    }

    const mouseUp = () => {
        document.getElementById("ButtonYes").style.color="black";
        document.getElementById("ButtonNo").style.color="black";
    }


    useEffect(() => {
        try {
          const localStorageAnswers = localStorage.setItem("respuesta");

          let parsedRespuesta;

          if(!localStorageAnswers) {
            localStorageAnswers.setItem("respuesta", JSON.stringify(buttom));

            parsedRespuesta = buttom;
          } else {
            parsedRespuesta = JSON.parse(localStorageAnswers.getItem("respuesta"));
          }
          setButtom(parsedRespuesta)
        } catch (error) {
            setError(error);
        }
    }, [])

    return (
        <>


            <button style={{ display: "flex", alignSelf: "flex-start", background: "aqua", border: "aqua", borderRadius: "5px", padding: "5px", textAlign: "center", cursor: "pointer", margin: "15px"
            }}  
                onClick={() => actualizarPreguntaAnterior() }
            >↶</button>

            <header className='header-preguntas' >
                <h1>{title}</h1>
                <h4>{description}</h4>
            </header> 

            <section className="seccionButton" >
                {
                    [...new Array(1)].map((btn, i) => {
                        
                        return (
                            <>
                                <button 
                                    id='ButtonYes'
                                    className={ `BotonNo ${ buttonActiveSi ? 'isActive' : ''} `}
                                    value="1"
                                    onClick={ clickBotonSi }
                                    onMouseDown={mouseDown}
                                    onMouseUp={mouseUp}
                                   
                                >Si</button>

                                {/* // { `BotonNo` ${ true ? "isActive" : " "} } */}
                                <button id='ButtonNo'
                                className={ `BotonNo ${ buttonActive ? 'isActive' : ''} `}
                                    value="2" 
                                    onClick={ clickBotonNo }
                                    onMouseDown={mouseDownNo}
                                    onMouseUp={mouseUp}
                                >No</button>
                            </>
                        )
                        
                    })
                }
            </section>
            <button 
                className="boton" 
                onClick={ () => saveDataButton() } 
                style={{ marginTop: "110px", border: "1px solid #00F2D7", background: "#00F2D7", 
                    padding: "9px",
                    cursor: "pointer",
                    width: "272px",
                    borderRadius: "12px" 
                }} 
                
            >Siguiente</button>
        </>
    )
}

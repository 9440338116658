import React from "react";
import logoPlaizt from "../Imagenes/Logo.svg";
import decoracion from "../Imagenes/imgdecoration.svg";
import "./NavbarPreguntas.css";
import { NavbarLogos } from "../NavbarLogos/NavbarLogos";

export const NavbarPreguntas = ({ logo }) => {
  return (
    <>
      {/* <header className='header-preguntas'> */}
      <nav>
        {(() => {
          if (logo !== undefined) {
            return (
              <img
                src={logo}
                alt="logoCompany"
                style={{
                    position: "absolute",
                    zIndex: "1",
                    left: "50px",
                    top: "20px",
                    maxHeight: "120px",
                }}
              ></img>
            );
          } else {
            return (
              <img
                src={logoPlaizt}
                style={{
                  position: "absolute",
                  zIndex: "1",
                  left: "5px",
                  top: "65px",
                }}
                alt="logoPlaizt"
                className="logoPlaiztCarga"
              ></img>
            );
          }
        })()}

        <img
          src={decoracion}
          alt="decoracion"
          style={{ position: "absolute", width: "137px", zIndex: "0" }}
          className="decoracion"
        ></img>
      </nav>
    </>
  );
};

import React, { useEffect, useState } from 'react'

export const PreguntaRespuesta = props => {

    const {title, description, id, isLast, enviarData, actualizarPreguntaActual, actualizarPreguntaAnterior } = props;

    const [inputChange, setInputChange] = useState(); 
    const [respuesta, setRespuesta] = useState(inputChange); // preguntas
    const [error, setError ] = useState(false);

    const onInputChange = (e) => {
           const text = e.target.value;
           setInputChange(text)
    }


    const saveDataBoton = () => {
        if (inputChange === undefined) {
            alert("Por favor ingresa la información");
        } else {
            const respuesta = {
                "id": id, "answers": inputChange 
            }
            localStorage.setItem(id, JSON.stringify(respuesta));
    
            if (isLast){
                enviarData();
            } else {
                actualizarPreguntaActual();
            }
        }
    }

    useEffect(() => {
        setTimeout(() => {

            try {
                // Pregunta de almacenamiento local
                const localStorageQuestion = localStorage.setItem("respuesta"); // guarda info en el navegador.
                // getItem, setItem son metodos

                let parsedRespuesta; // var nueva lista de array preguntas.

                if(!localStorageQuestion) {
                    localStorage.setItem("respuesta", JSON.stringify(inputChange)); // envia info en forma de string
                    parsedRespuesta = inputChange; // estado de la app, array vacio, cuando no hayan preguntas.
                } else {
                    parsedRespuesta = JSON.parse(localStorageQuestion.getItem("respuesta")); // JSON.parse se convierte la información en string.
                }
                setRespuesta(parsedRespuesta);
            } catch(error) {
                setError(error);
            }
        }, 2000);
    }, []); 
    

    return (
        <>
            <button style={{ display: "flex", alignSelf: "flex-start", background: "aqua", border: "aqua", borderRadius: "5px", padding: "5px", textAlign: "center", cursor: "pointer", margin: "15px"
            }}  
                onClick={() => actualizarPreguntaAnterior() }
            >↶</button>




            <header className='header-preguntas' >
                <h1>{title}</h1>
                <h4>{description}</h4>
            </header> 

            <form className='container-textarea'>
                <textarea   
                    className='cajaTexto'
                    placeholder= "Escriba la respuesta"
                    onChange={ onInputChange  }
                    value={inputChange}
                    style={{width: "238px", height: "125px", padding: "6px", borderRadius: "6px", border: "2px solid hotpink", backgroundColor: "#F3F2F4", fontFamily: 'Segoe UI', 
                    }} 
                ></textarea>              
            </form>
            
            <button 
                className="boton" 
                onClick={ () => saveDataBoton() } 
                style={{ marginTop: "110px", border: "1px solid #00F2D7", background: "#00F2D7", padding: "9px",
                    cursor: "pointer",
                    width: "272px",
                    borderRadius: "12px" }} 
            >Siguiente</button>
        </>
    )
}
